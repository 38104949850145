import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import DocumentsViewerFooter from "../DocumentsViewerFooter";
import "./PdfViewer.style.scss";

/*global console*/

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

// eslint-disable-next-line react/prop-types
const PdfViewer = ({ src }) => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const onPageChanged = (page) => {
    setPageNumber(page);
  };
  const onScaleChanged = (newScale) => {
    setScale(newScale);
  };
  return (
    <>
      <div>
        <Document
          file={src}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(error) => console.log("Error while loading document! " + error.message)}
        >
          <div className="PDFViewerContainer">
            <div className="PDFViewerPageContainer">
              <Page pageNumber={pageNumber} renderAnnotationLayer={false} renderTextLayer={false} scale={scale} />
            </div>
          </div>
        </Document>
      </div>

      <DocumentsViewerFooter
        scaleEnabled={true}
        pageCount={numPages}
        onPageChanged={onPageChanged}
        onScaleChanged={onScaleChanged}
        scale={scale}
      />
    </>
  );
};

export default PdfViewer;

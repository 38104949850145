/* eslint-disable react/prop-types */
import React, { useState } from "react";
import {
  ArrowLeftFilled,
  ArrowRightFilled,
  AddFilled,
  SubtractFilled,
  ArrowMinimizeRegular,
} from "@fluentui/react-icons";

import "./DocumentsViewerFooter.style.scss";

const DocumentsViewerFooter = ({ pageCount, onPageChanged, scaleEnabled = false, scale = 0.6, onScaleChanged }) => {
  const [page, setPage] = useState(1);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    onPageChanged(newPage);
  };

  const handleScaleChange = (value) => {
    let newScale = scale + value;
    if (newScale < -10) {
      newScale = 1;
    }
    if (newScale > 12) {
      newScale = 12;
    }
    onScaleChanged && onScaleChanged(newScale);
  };
  return (
    <div className="DocumentsViewerFooter">
      <ArrowLeftFilled
        className={page <= 1 ? "disabledIcon" : ""}
        onClick={() => {
          if (page > 1) {
            const newPage = page - 1;
            handlePageChange(newPage);
          }
        }}
        style={{
          padding: 0,
          color: "#ffffff",
          fontSize: "14px",
          cursor: "pointer",
        }}
      />
      <div
        style={{
          background: "#0E47FF",
          height: "30px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <p style={{ margin: "0 10px", display: "flex", alignItems: "center" }}>
          {page} / {pageCount}
        </p>
      </div>

      <ArrowRightFilled
        onClick={() => {
          if (page < pageCount) {
            const newPage = page + 1;
            handlePageChange(newPage);
          }
        }}
        style={{
          padding: 0,
          color: "#ffffff",
          fontSize: "14px",
          cursor: "pointer",
        }}
        className={page === pageCount ? "disabledIcon" : ""}
      />

      {scaleEnabled && (
        <div
          style={{
            display: "flex",
            gap: 3,
            marginRight: 4,
            marginLeft: 4,
          }}
        >
          <AddFilled
            disabled={scale === 12}
            onClick={() => handleScaleChange(5 / 100)}
            style={{
              padding: 0,
              color: "#ffffff",
              fontSize: "14px",
              cursor: "pointer",
            }}
          />
          <ArrowMinimizeRegular
            disabled={scale === 0.5}
            onClick={() => onScaleChanged && onScaleChanged(0.5)}
            style={{
              padding: 0,
              color: "#ffffff",
              fontSize: "14px",
              cursor: "pointer",
            }}
          />
          <SubtractFilled
            disabled={scale <= 0.3}
            onClick={() => handleScaleChange(-(5 / 100))}
            style={{
              padding: 0,
              color: "#ffffff",
              fontSize: "14px",
              cursor: "pointer",
            }}
          />
        </div>
      )}
    </div>
  );
};
export default DocumentsViewerFooter;

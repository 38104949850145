import React from "react";
import dayjs from "dayjs";

/*global */
// const channel = new BroadcastChannel("office-addin-channel");

const ListItem = (props) => {
  const { columns, file } = props;

  return columns.map((column) => {
    return column.key === "uploadDate" ? (
      <div key={file.id + column.key} className="table_cell_content">
        {column.key in file ? dayjs(file[column.key]).format("D/MM/YYYY") : column.value}
      </div>
    ) : (
      <div key={file.id + column.key} className="table_cell_content">
        {column.key in file ? file[column.key] : column.value}
      </div>
    );
  });
};

export default ListItem;

import React, { useEffect, useState } from "react";
// import axiosInstance from "../../api/apiService";
// import { login } from "../../api/endoints";
import DocumentView from "./DocumentView/DocumentView";
import "./app.css";
import useBroadCastUserChannel from "../../hooks/useBroadCastUserChannel";
import { disableActions } from "../../commands/actionsCommands";
import Unauthorized from "./Unauthorized";
/*
  global console BroadcastChannel
*/

const App = () => {
  const [messageFromModal, setMessageFromModal] = useState("");

  const { loggedIn } = useBroadCastUserChannel();
  useEffect(() => {
    if (!loggedIn) {
      disableActions();
    }
  });
  const channel = new BroadcastChannel("office-addin-channel");
  useEffect(() => {
    console.log(messageFromModal, "messageFromModal;");
  }, [messageFromModal]);
  useEffect(() => {
    channel.onmessage = (event) => {
      const message = event.data;
      if (message.type === "FROM_MODAL") {
        console.log("Message from modal:", message.payload);
        setMessageFromModal(message.payload);
      }
    };

    return () => {
      channel.close();
    };
  }, [channel]);

  return (
    <div className="app-wrapper">
      {loggedIn ? (
        <DocumentView />
      ) : (
        <div className="not_authorized">
          <Unauthorized />
        </div>
      )}
    </div>
  );
};

export default App;

/* global Office console  window  Excel indexedDB localStorage*/

import { saveDocument, finishDocument, reopenDocument } from "../api/actionsService";

export const disableActions = async () => {
  const buttonSave = { id: "Save", enabled: false, visible: true };
  const buttonFinish = { id: "Finish", enabled: false, visible: true };
  const buttonReopen = { id: "Reopen", enabled: false, visible: true };

  const parentGroup = { id: "GroupActions", controls: [buttonSave, buttonFinish, buttonReopen] };
  const parentTab = { id: "TabCustom", groups: [parentGroup] };
  const ribbonUpdater = [parentTab];
  await Office.ribbon.requestUpdate({
    tabs: ribbonUpdater,
  });
};

export const enabledSaveFinish = async (enabled) => {
  const buttonSave = { id: "Save", enabled: enabled, visible: true };
  const buttonFinish = { id: "Finish", enabled: enabled, visible: true };
  const buttonReopen = { id: "Reopen", enabled: false, visible: true };

  const parentGroup = { id: "GroupActions", controls: [buttonSave, buttonFinish, buttonReopen] };
  const parentTab = { id: "TabCustom", groups: [parentGroup] };
  const ribbonUpdater = [parentTab];
  await Office.ribbon.requestUpdate({
    tabs: ribbonUpdater,
  });
};

export const enabledReopen = async () => {
  const buttonReopen = { id: "Reopen", enabled: true, visible: true };
  const parentGroup = { id: "GroupActions", controls: [buttonReopen] };
  const parentTab = { id: "TabCustom", groups: [parentGroup] };
  const ribbonUpdater = [parentTab];
  await Office.ribbon.requestUpdate({
    tabs: ribbonUpdater,
  });
};

Office.onReady((info) => {
  if (info.host === Office.HostType.Excel) {
    console.info("Excel is ready.");
    window.saveHandler = (event, action) => {
      console.info(action, "Custom function executed.");
      try {
        getInsertedData();
      } catch (error) {
        console.info("Error in userHandler:", error);
      }
      event?.completed();
    };
  }
});

Office.onReady((info) => {
  if (info.host === Office.HostType.Excel) {
    console.info("Excel is ready.");
    window.finishHandler = (event, action) => {
      console.info(action, "Custom function executed.");
      try {
        finishSavedDocument();
      } catch (error) {
        console.info("Error in userHandler:", error);
      }
      event?.completed();
    };
  }
});

Office.onReady((info) => {
  if (info.host === Office.HostType.Excel) {
    console.info("Excel is ready.");
    window.reopenHandler = (event, action) => {
      console.info(action, "Custom function executed.");
      try {
        const scopeId = localStorage.getItem("scopeId");

        reopenDocument(scopeId).then(async () => {
          await enabledSaveFinish(true);
        });
      } catch (error) {
        console.info("Error in userHandler:", error);
      }
      event?.completed();
    };
  }
});

export async function getInsertedData() {
  try {
    await Excel.run(async (context) => {
      const sheet = context.workbook.worksheets.getActiveWorksheet();

      if (!sheet) {
        console.error("Error: No active worksheet found.");
        return null;
      }

      const usedRange = sheet.getUsedRange();
      usedRange.load("values"); // Load the values property of the range

      await context.sync(); // Sync the context to get the values

      const data = usedRange.values;
      console.log("Data retrieved successfully:", data);

      const request = indexedDB.open("ontologyDatabase", 1);

      request.onsuccess = function (event) {
        const db = event.target.result;

        // 2. Start a transaction to read from the object store
        const transaction = db.transaction("ontologyObjectStore", "readonly");
        const objectStore = transaction.objectStore("ontologyObjectStore");

        // 3. Read a specific object by key
        const scopeId = localStorage.getItem("scopeId");
        const getRequest = objectStore.get(scopeId);

        getRequest.onsuccess = async function () {
          if (getRequest.result) {
            console.log("Object retrieved:", getRequest.result);
            const ontology = getRequest.result.application;
            if (ontology.fields) {
              const propertiesId = ontology.properties.map(({ id }) => id);
              const newOntology = Object.assign({}, ontology);
              //need optimization
              setValues(ontology, propertiesId, data);
              await enabledSaveFinish(false);
              saveDocument(scopeId, newOntology).then(async () => {
                await enabledSaveFinish(true);
                const transaction = db.transaction("ontologyObjectStore", "readwrite");
                const objectStore = transaction.objectStore("ontologyObjectStore");
                const updateRequest = objectStore.put({ id: scopeId, application: newOntology });
                updateRequest.onsuccess = function () {
                  console.log("Object updated successfully:", newOntology);
                };
                updateRequest.onerror = function () {
                  console.log("Error updating object");
                };
              });

              console.log("newOntology", newOntology);
            }
          } else {
            console.log("No record found for the given key");
          }
        };

        getRequest.onerror = function () {
          console.log("Error retrieving object");
        };
      };
    });
  } catch (error) {
    console.error("Error retrieving data:", error);
    return null;
  }
}

function setValues(ontology, propertiesId, data) {
  for (let i = 0; i < ontology.fields.length; i++) {
    const field = ontology.fields[i];
    for (let j = 0; j < field.children.length; j++) {
      const children = field.children[j];
      const childrenLabel = children.label;
      propertiesId.map((id, index) => {
        const value = children.values[id][0];
        if (!value.locked) {
          const itemValue = data.find((el) => Array.isArray(el) && el[0] === childrenLabel);
          //   console.log(itemValue.length, "index");
          //   console.log(itemValue[index]);
          if (itemValue.length >= index) {
            value.value = itemValue[index];
          }
          //   console.log(value);
        }
      });
    }
  }
}

export async function finishSavedDocument() {
  try {
    await Excel.run(async (context) => {
      const sheet = context.workbook.worksheets.getActiveWorksheet();

      if (!sheet) {
        console.error("Error: No active worksheet found.");
        return null;
      }

      const request = indexedDB.open("ontologyDatabase", 1);

      request.onsuccess = function (event) {
        const db = event.target.result;

        // 2. Start a transaction to read from the object store
        const transaction = db.transaction("ontologyObjectStore", "readonly");
        const objectStore = transaction.objectStore("ontologyObjectStore");

        // 3. Read a specific object by key
        const scopeId = localStorage.getItem("scopeId");
        const getRequest = objectStore.get(scopeId);

        getRequest.onsuccess = async function () {
          if (getRequest.result) {
            console.log("Object retrieved:", getRequest.result);
            const ontology = getRequest.result.application;
            if (ontology.fields) {
              await enabledSaveFinish(false);

              finishDocument(scopeId, ontology).then(async () => {
                await enabledReopen();
              });
            }
          } else {
            console.log("No record found for the given key");
          }
        };

        getRequest.onerror = function () {
          console.log("Error retrieving object");
        };
      };

      // Get the range of the used data

      //   return data; // Return the data for further processing
    });
  } catch (error) {
    console.error("Error retrieving data:", error);
    return null;
  }
}

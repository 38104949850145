/* global Excel console */

export async function insertText(text) {
  // Write text to the top left cell.
  try {
    await Excel.run(async (context) => {
      const sheet = context.workbook.worksheets.getActiveWorksheet();
      const range = sheet.getRange("A1");
      range.values = [[text]];
      range.format.autofitColumns();
      await context.sync();
    });
  } catch (error) {
    console.log("Error: " + error);
  }
}
export async function insertOntologies(ontology, header, sheetType) {
  console.log(ontology);
  try {
    await Excel.run(async (context) => {
      let sheet;

      if (sheetType === "existing") {
        sheet = context.workbook.worksheets.getActiveWorksheet();
      } else if (sheetType === "new") {
        const sheets = context.workbook.worksheets;
        sheets.load("items");
        await context.sync(); // Synchronize the context to fetch the sheet data
        const sheetNames = sheets.items.map((sheet) => sheet.name);
        let newSheetIndex = 1;
        let newSheetName = `Sheet${newSheetIndex}`;

        // Find the next available sheet name that is not already taken
        while (sheetNames.includes(newSheetName)) {
          newSheetIndex++;
          newSheetName = `Sheet${newSheetIndex}`;
        }

        sheet = sheets.add(newSheetName);
        sheet.activate();
      }

      if (!sheet) {
        console.error("Error: No active worksheet found.");
        return;
      }

      // Clear existing data and ungroup
      const usedRange = sheet.getUsedRange();
      if (usedRange) {
        usedRange.clear();
        usedRange.ungroup(); // Ungroup all ranges
      }
      // const rangeToUnLock = sheet.getRange("A1:B1"); // Specify the range you want to lock
      // rangeToUnLock.format.protection.locked = false;
      if (header.length > 0) {
        const headerRow = header; // Get the first row of headers
        const columnCount = headerRow.length; // Get number of columns in the header row
        const endColumn = String.fromCharCode(64 + columnCount); // Convert number to letter (e.g., 1 -> A, 2 -> B, etc.)
        const headerRange = sheet.getRange(`A1:${endColumn}1`); // Define the range for headers
        headerRange.values = [headerRow]; // Set the header values in the first row
        headerRange.format.font.bold = true; // Make the header bold

        headerRange.format.fill.color = "#074CE5";
        headerRange.format.font.color = "#FFFFFF";
      }

      // Helper function to insert a group and return the range
      const insertGroup = (data, startRow) => {
        const endRow = startRow + data.length - 1;
        const range = sheet.getRange(`A${startRow}:B${endRow}`);
        range.values = data;
        range.format.autofitColumns();

        // Set the first value of the group to bold
        const headerRange = sheet.getRange(`A${startRow}`);
        headerRange.format.font.bold = true;

        headerRange.format.autofitColumns();
        return { startRow, endRow };
      };

      // Loop through ontology array to insert groups
      let currentRow = 2; // Starting row
      ontology.forEach((groupData) => {
        const group = insertGroup(groupData, currentRow);
        currentRow = group.endRow + 2; // Add 1 row as spacer after each group

        // Group rows in Excel (skip header row for each group)
        sheet.getRange(`A${group.startRow + 1}:A${group.endRow}`).group();
      });
      // Apply changes with a single sync
      await context.sync();
      const allCells = sheet.getRange("A1:XFD1048576"); // Unlock all possible cells
      allCells.format.protection.locked = false;

      // Lock specific cells
      const rangeToLock = sheet.getRange("A1:B1"); // Specify the range you want to lock
      rangeToLock.format.protection.locked = true;

      // Protect the sheet to apply the lock
      // const password = "12345678"; // Replace with the desired password
      // sheet.protection.protect({}, password);

      await context.sync();

      console.log("Data written successfully with groups.");
    });
  } catch (error) {
    console.error("Error: ", error);
  }
}
